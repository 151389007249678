
import Vue from 'vue';

import { mapActions } from 'vuex';

import LangSelector from '@/components/helper/LangSelect.vue';

import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';

import {
    required,
    minLength,
} from 'vuelidate/lib/validators';

import { mdiPencil } from '@mdi/js';
import moment from 'moment';

import {
    Bold,
    Italic,
    Link,
    Underline,
    Placeholder,
} from 'tiptap-extensions';

import { newsActions } from '@/store/modules/news/names';

export default Vue.extend({
    name: 'AddNews',

    validations: {
        post: {
            language: { required },
            title: { required },
            published_date: { required },
            text: { required, minLength: minLength(20) },
        },
    },
    components: {
        LangSelector,
        EditorContent,
        EditorMenuBubble,
    },
    data() {
        return {
            linkUrl: null,
            linkMenuIsActive: false,

            process: false,
            alertSuccess: false,
            alertError: false,

            btnUpdate: false,

            isDatepickerShown: false,
            todayDate: moment().format('YYYY-MM-DD'),
            dateIcon: mdiPencil,

            post: {
                language: null,
                title: null,
                text: null,
                published_date: null,
            },

            keepInBounds: true,
            editor: new Editor({
                extensions: [
                    new Link(),
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: 'text…',
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
                content: '',
                onUpdate: ({ getHTML }) => {
                    // @ts-ignore
                    this.post.text = getHTML();
                },
            }),
        };
    },
    computed: {
        titleError() {
            const errors: string[] = [];
            if (!this.$v.post.title?.$dirty) return errors;
            if (!this.$v.post.title?.required) {
                errors.push('Title is required.');
            };
            return errors;
        },
        textError() {
            const errors: string[] = [];
            if (!this.$v.post.text?.$dirty) return errors;
            if (!this.$v.post.text?.required) {
                errors.push('Text is required.');
            }
            if (!this.$v.post.text?.minLength) {
                errors.push('In the text field, the minimum number of characters is 20!');
            }
            return errors;
        },
        languageError() {
            const errors: string[] = [];
            if (!this.$v.post.language?.$dirty) return errors;
            if (!this.$v.post.language?.required) {
                errors.push('Language is required.');
            }
            return errors;
        },
    },
    mounted() {
        document.addEventListener('click', this.hideDatepicker);
    },
    beforeDestroy() {
        this.editor.destroy();
        document.removeEventListener('click', this.hideDatepicker);
    },
    methods: {
        ...mapActions({
            createNews: newsActions.createNews,
        }),
        hideDatepicker(event) {
            const buttonEl = event.target.closest('#datepicker-open-button');
            const newsEl = event.target.closest('#news-menu');
            
            if (buttonEl || newsEl) return;

            this.isDatepickerShown = false;
        },
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;

            this.$nextTick(() => {
                (this.$refs.linkInput as HTMLInputElement).focus();
            });
        },

        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },

        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
        onFileChange(file) {
            if (!file) return;
            this.createImage(file);
        },
        createImage(file) {
            const image = new Image();
            const reader = new FileReader();
            const vm = this;

            reader.onload = (e) => {

                vm[image as any] = e.target?.result;
            };

            reader.readAsDataURL(file);
        },
        updateAlerts() {
            this.alertSuccess = false;
            this.alertError = false;
        },
        resetPostModels() {
            this.$v.$reset();

            this.editor.clearContent(true);

            this.btnUpdate = false;
            this.post = {
                language: null,
                title: null,
                text: null,
                published_date: null,
            };
        },
        async submit() {
            this.$v.post.$touch();

            if (!this.$v.post.$error) {
                this.process = true;
                const formData = new FormData();

                Object.keys(this.post).forEach((key) => {
                    formData.append(key, this.post[key]);
                });

                await this.createNews(formData).then(() => {
                    this.alertSuccess = true;
                    this.process = false;
                    this.btnUpdate = true;

                    setTimeout(() => {
                        this.updateAlerts();
                        this.$router.replace({ name: 'News' });
                    }, 3000);
                });
            }
        },
    },
});
